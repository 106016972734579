// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/commons/UIUtilsTemplate.soy

/**
 * @fileoverview Templates in namespace ts.commons.UIUtilsTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import {SanitizedHtml as goog_soy_data_SanitizedHtml} from 'ts-closure-library/lib/soy/data';
import * as google from 'ts-closure-library/lib/google';
import * as goog_html from 'ts-closure-library/lib/html/safehtml';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './LinkTemplate.soy.generated.js';


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $alerts = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.alerts']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.alerts'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui error message sticky validation-errors" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:23"' : '') + '><i class="exclamation circle icon"></i></div><div class="ui warning message validation-warnings" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:26"' : '') + '><i class="exclamation triangle icon"></i></div><div class="ui success message sticky validation-infos" style="display: none;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.alerts ts/commons/UIUtilsTemplate.soy:29"' : '') + '><i class="check circle icon"></i></div>');
};
export { $alerts as alerts };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $alerts.soyTemplateName = 'ts.commons.UIUtilsTemplate.alerts';
}


/**
 * @param {!$absoluteAndRelativeDateInput.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $absoluteAndRelativeDateInput = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $absoluteAndRelativeDateInput$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.name);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} name
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $absoluteAndRelativeDateInput$ = function($$areYouAnInternalCaller, $ijData, name) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput']({name: name}, $ijData);
  }
  soy.assertParamType(typeof name === 'string', 'name', name, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div data-name="' + soy.$$escapeHtmlAttribute(name) + '" class="date-picker ' + soy.$$escapeHtmlAttribute(name) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput ts/commons/UIUtilsTemplate.soy:39"' : '') + '></div>');
};
export { $absoluteAndRelativeDateInput as absoluteAndRelativeDateInput };
export { $absoluteAndRelativeDateInput$ as absoluteAndRelativeDateInput$ };
/**
 * @typedef {{
 *  name: string,
 * }}
 */
$absoluteAndRelativeDateInput.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $absoluteAndRelativeDateInput.soyTemplateName = 'ts.commons.UIUtilsTemplate.absoluteAndRelativeDateInput';
}


/**
 * @param {!$centeredDiv.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $centeredDiv = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $centeredDiv$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.content, opt_data.useFullWidth, opt_data.fullWidth, opt_data.containerSegment);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string} content
 * @param {boolean|null|undefined=} useFullWidth
 * @param {null|string|undefined=} fullWidth
 * @param {boolean|null|undefined=} containerSegment
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $centeredDiv$ = function($$areYouAnInternalCaller, $ijData, content, useFullWidth, fullWidth, containerSegment) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.centeredDiv']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.centeredDiv']({content: content, useFullWidth: useFullWidth, fullWidth: fullWidth, containerSegment: containerSegment}, $ijData);
  }
  soy.assertParamType(goog_soy_data_SanitizedHtml.isCompatibleWith(content), 'content', content, '@param', '!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string');
  soy.assertParamType(useFullWidth == null || typeof useFullWidth === 'boolean', 'useFullWidth', useFullWidth, '@param', 'boolean|null|undefined');
  soy.assertParamType(fullWidth == null || typeof fullWidth === 'string', 'fullWidth', fullWidth, '@param', 'null|string|undefined');
  soy.assertParamType(containerSegment == null || typeof containerSegment === 'boolean', 'containerSegment', containerSegment, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (!useFullWidth ? ' class="ui centered container ' + soy.$$escapeHtmlAttribute(containerSegment ? 'segment' : '') + '"' : '') + ' style="' + (!useFullWidth ? 'max-width: ' + (fullWidth ? soy.$$escapeHtmlAttribute(soy.$$filterCssValue(fullWidth)) : '900px') + '!important' : '') + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.centeredDiv ts/commons/UIUtilsTemplate.soy:52"' : '') + '>' + soy.$$escapeHtml(content) + '</div>');
};
export { $centeredDiv as centeredDiv };
export { $centeredDiv$ as centeredDiv$ };
/**
 * @typedef {{
 *  content: (!goog_html.SafeHtml|!goog_soy_data_SanitizedHtml|!soy.$$EMPTY_STRING_|string),
 *  useFullWidth?: (boolean|null|undefined),
 *  fullWidth?: (null|string|undefined),
 *  containerSegment?: (boolean|null|undefined),
 * }}
 */
$centeredDiv.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $centeredDiv.soyTemplateName = 'ts.commons.UIUtilsTemplate.centeredDiv';
}


/**
 * @param {!$singleOrMultipleEntriesDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $singleOrMultipleEntriesDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $singleOrMultipleEntriesDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.entries, opt_data.multiSelection, opt_data.textFilterEnabled);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} entries
 * @param {?} multiSelection
 * @param {?} textFilterEnabled
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $singleOrMultipleEntriesDialog$ = function($$areYouAnInternalCaller, $ijData, entries, multiSelection, textFilterEnabled) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog']({entries: entries, multiSelection: multiSelection, textFilterEnabled: textFilterEnabled}, $ijData);
  }
  let $output = (textFilterEnabled ? '<div class="ui small fluid input" style="padding-right: 15px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:71"' : '') + '><input class="entry-filter filterText" type="text" placeholder="type filter text..." style="width: 100%"></div><div class="ui divider"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:74"' : '') + '></div>' : '') + '<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog ts/commons/UIUtilsTemplate.soy:76"' : '') + '><div class="grouped fields">';
  const entry1295List = entries;
  const entry1295ListLen = entry1295List.length;
  for (let entry1295Index = 0; entry1295Index < entry1295ListLen; entry1295Index++) {
    const entry1295Data = entry1295List[entry1295Index];
    $output += '<div class="field" id="entry-field-' + soy.$$escapeHtmlAttribute(entry1295Data.name) + '"' + (entry1295Data.description ? soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + entry1295Data.description)) : '') + '><div class="ui ' + (!multiSelection ? 'radio' : '') + ' checkbox"><input class="entry-marker" data-entry-checkbox="true" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(entry1295Data.name) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(entry1295Data.name) : 'metric') + '"' + (entry1295Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(entry1295Data.name) + '" class="entry-marker-title">' + soy.$$escapeHtml(entry1295Data.name) + '</label></div></div>';
  }
  $output += '</div></div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $singleOrMultipleEntriesDialog as singleOrMultipleEntriesDialog };
export { $singleOrMultipleEntriesDialog$ as singleOrMultipleEntriesDialog$ };
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 *  textFilterEnabled: ?,
 * }}
 */
$singleOrMultipleEntriesDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $singleOrMultipleEntriesDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.singleOrMultipleEntriesDialog';
}


/**
 * @param {!$hierarchicGroupedEntriesDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $hierarchicGroupedEntriesDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $hierarchicGroupedEntriesDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.groups, opt_data.multiSelection);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} groups
 * @param {?} multiSelection
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $hierarchicGroupedEntriesDialog$ = function($$areYouAnInternalCaller, $ijData, groups, multiSelection) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog']({groups: groups, multiSelection: multiSelection}, $ijData);
  }
  let $output = '<ul class="unstyled"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog ts/commons/UIUtilsTemplate.soy:115"' : '') + '>';
  const group1388List = groups;
  const group1388ListLen = group1388List.length;
  for (let group1388Index = 0; group1388Index < group1388ListLen; group1388Index++) {
    const group1388Data = group1388List[group1388Index];
    $output += '<li class="group-marker"><div class="ui ' + (multiSelection ? 'checkbox' : 'radio') + '"><input class="group-input-marker" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(group1388Data.name) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(group1388Data.name) : 'metric') + '"' + (group1388Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(group1388Data.name) + '"><abbr>' + soy.$$escapeHtml(group1388Data.name) + '</abbr></label></div><div style="padding-left:10px">' + $singleOrMultipleEntriesWithDisplayNameDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, group1388Data[/** @type {?} */ ('metrics')], multiSelection) + '</div></li>';
  }
  $output += '</ul>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $hierarchicGroupedEntriesDialog as hierarchicGroupedEntriesDialog };
export { $hierarchicGroupedEntriesDialog$ as hierarchicGroupedEntriesDialog$ };
/**
 * @typedef {{
 *  groups: ?,
 *  multiSelection: ?,
 * }}
 */
$hierarchicGroupedEntriesDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $hierarchicGroupedEntriesDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.hierarchicGroupedEntriesDialog';
}


/**
 * @param {!$singleOrMultipleEntriesWithDisplayNameDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $singleOrMultipleEntriesWithDisplayNameDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $singleOrMultipleEntriesWithDisplayNameDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.entries, opt_data.multiSelection);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} entries
 * @param {?} multiSelection
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $singleOrMultipleEntriesWithDisplayNameDialog$ = function($$areYouAnInternalCaller, $ijData, entries, multiSelection) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog']({entries: entries, multiSelection: multiSelection}, $ijData);
  }
  let $output = '<ul class="unstyled"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog ts/commons/UIUtilsTemplate.soy:149"' : '') + '>';
  const entry1476List = entries;
  const entry1476ListLen = entry1476List.length;
  for (let entry1476Index = 0; entry1476Index < entry1476ListLen; entry1476Index++) {
    const entry1476Data = entry1476List[entry1476Index];
    $output += '<li><div class="ui ' + (multiSelection ? 'checkbox' : 'radio') + '"><input class="entry-marker" type="' + (multiSelection ? 'checkbox' : 'radio') + '" id="' + soy.$$escapeHtmlAttribute(entry1476Data.displayName) + '" name="' + (multiSelection ? soy.$$escapeHtmlAttribute(entry1476Data.name) : 'metric') + '"' + (entry1476Data.isSelected ? ' checked="checked"' : '') + '><label for="' + soy.$$escapeHtmlAttribute(entry1476Data.displayName) + '"><abbr' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + entry1476Data.description)) + '>' + soy.$$escapeHtml(entry1476Data.displayName) + '</abbr></label></div></li>';
  }
  $output += '</ul>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 * }}
 */
$singleOrMultipleEntriesWithDisplayNameDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $singleOrMultipleEntriesWithDisplayNameDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.singleOrMultipleEntriesWithDisplayNameDialog';
}


/**
 * @param {!$divWithIdWrapper.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $divWithIdWrapper = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $divWithIdWrapper$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.id, opt_data.content);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} id
 * @param {?} content
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $divWithIdWrapper$ = function($$areYouAnInternalCaller, $ijData, id, content) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithIdWrapper']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithIdWrapper']({id: id, content: content}, $ijData);
  }
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="' + soy.$$escapeHtmlAttribute(id) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.divWithIdWrapper ts/commons/UIUtilsTemplate.soy:178"' : '') + '>' + soy.$$escapeHtml(content) + '</div>');
};
export { $divWithIdWrapper as divWithIdWrapper };
export { $divWithIdWrapper$ as divWithIdWrapper$ };
/**
 * @typedef {{
 *  id: string,
 *  content: ?,
 * }}
 */
$divWithIdWrapper.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $divWithIdWrapper.soyTemplateName = 'ts.commons.UIUtilsTemplate.divWithIdWrapper';
}


/**
 * @param {!$divWithClass.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $divWithClass = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $divWithClass$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.styleClass);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} styleClass
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $divWithClass$ = function($$areYouAnInternalCaller, $ijData, styleClass) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithClass']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.divWithClass']({styleClass: styleClass}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="' + soy.$$escapeHtmlAttribute(styleClass) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.divWithClass ts/commons/UIUtilsTemplate.soy:191"' : '') + '></div>');
};
export { $divWithClass as divWithClass };
export { $divWithClass$ as divWithClass$ };
/**
 * @typedef {{
 *  styleClass: ?,
 * }}
 */
$divWithClass.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $divWithClass.soyTemplateName = 'ts.commons.UIUtilsTemplate.divWithClass';
}


/**
 * @param {?$reAnalysisIndicator.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $reAnalysisIndicator = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $reAnalysisIndicator$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.requiresNoReAnalysis);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?=} requiresNoReAnalysis
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $reAnalysisIndicator$ = function($$areYouAnInternalCaller, $ijData, requiresNoReAnalysis) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.reAnalysisIndicator']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.reAnalysisIndicator']({requiresNoReAnalysis: requiresNoReAnalysis}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Changing this parameter ' + (requiresNoReAnalysis ? 'does not require' : 'requires') + ' a re-analysis of the project')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.reAnalysisIndicator ts/commons/UIUtilsTemplate.soy:205"' : '') + '><span class="reanalyze-indicator' + (requiresNoReAnalysis ? ' safe' : ' required') + '"></span></span>');
};
export { $reAnalysisIndicator as reAnalysisIndicator };
export { $reAnalysisIndicator$ as reAnalysisIndicator$ };
/**
 * @typedef {{
 *  requiresNoReAnalysis?: (?|undefined),
 * }}
 */
$reAnalysisIndicator.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $reAnalysisIndicator.soyTemplateName = 'ts.commons.UIUtilsTemplate.reAnalysisIndicator';
}


/**
 * @param {!$metricTableConfigDialogContent.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $metricTableConfigDialogContent = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.metricTableConfigDialogContent']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.metricTableConfigDialogContent'](opt_data, $ijData);
  }
  /** @type {?} */
  const entries = opt_data.entries;
  /** @type {?} */
  const multiSelection = opt_data.multiSelection;
  /** @type {?} */
  const textFilterEnabled = opt_data.textFilterEnabled;
  /** @type {?} */
  const thresholdProfileNames = opt_data.thresholdProfileNames;
  /** @type {?} */
  const useAbbreviatedNumbers = opt_data.useAbbreviatedNumbers;
  /** @type {?} */
  const limitMetricsToProfile = opt_data.limitMetricsToProfile;
  /** @type {?} */
  const hideRowsWithoutData = opt_data.hideRowsWithoutData;
  /** @type {?} */
  const selectedThresholdProfile = opt_data.selectedThresholdProfile;
  let $output = '<h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:236"' : '') + '>Number Formatting</h5><div class="ui checkbox"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'If enabled, large numbers will be render with a suffix, e.g. 3.5k')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:237"' : '') + '><input type="checkbox" id="checkbox-abbreviate-numbers"' + (useAbbreviatedNumbers ? ' checked="true"' : '') + '><label for="checkbox-abbreviate-numbers" class="checkbox">Abbreviate large numbers in table,\ne.g. <i>3.5k</i></label></div><h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:245"' : '') + '>Threshold Profile  ' + $helpIcon$(soy.$$internalCallMarkerDoNotUse, $ijData, 'A threshold profile tells Teamscale how to assess metric values') + '</h5>';
  if (soy.$$coerceToBoolean(thresholdProfileNames) && thresholdProfileNames.length > 0) {
    $output += '<select id="threshold-select" class="ui dropdown"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:249"' : '') + '>';
    const profileName1668List = thresholdProfileNames;
    const profileName1668ListLen = profileName1668List.length;
    for (let profileName1668Index = 0; profileName1668Index < profileName1668ListLen; profileName1668Index++) {
      const profileName1668Data = profileName1668List[profileName1668Index];
      $output += '<option' + (soy.$$equals(profileName1668Data, selectedThresholdProfile) ? ' selected' : '') + '>' + soy.$$escapeHtml(profileName1668Data) + '</option>';
    }
    $output += '</select><div class="ui marginless basic segment"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:254"' : '') + '><div class="ui checkbox"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'If enabled, only metrics contained in the selected threshold profile will be shown.')) + '><input type="checkbox" id="checkbox-limit-to-profile"' + (limitMetricsToProfile ? ' checked="true"' : '') + '><label for="checkbox-limit-to-profile" class="checkbox">Only show metrics from this profile</label></div></div>';
  }
  $output += '<h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:266"' : '') + '>Table Rows</h5><div class="ui checkbox"' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, 'Hides table rows that only contain ' + '"n/a"' + ' entries')) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:267"' : '') + '><input type="checkbox" id="hide-rows-without-data"' + (hideRowsWithoutData ? ' checked="true"' : '') + '><label for="hide-rows-without-data" class="checkbox">Hide paths without any value</label></div><h5' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.metricTableConfigDialogContent ts/commons/UIUtilsTemplate.soy:275"' : '') + '>Metrics</h5>' + $singleOrMultipleEntriesDialog(/** @type {?} */ (opt_data), $ijData);
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $metricTableConfigDialogContent as metricTableConfigDialogContent };
/**
 * @typedef {{
 *  entries: ?,
 *  multiSelection: ?,
 *  textFilterEnabled: ?,
 *  thresholdProfileNames: ?,
 *  useAbbreviatedNumbers?: (?|undefined),
 *  limitMetricsToProfile?: (?|undefined),
 *  hideRowsWithoutData?: (?|undefined),
 *  selectedThresholdProfile?: (?|undefined),
 * }}
 */
$metricTableConfigDialogContent.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $metricTableConfigDialogContent.soyTemplateName = 'ts.commons.UIUtilsTemplate.metricTableConfigDialogContent';
}


/**
 * @param {!$helpIcon.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $helpIcon = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $helpIcon$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $helpIcon$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.helpIcon']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.helpIcon']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span' + soy.$$whitespaceHtmlAttributes($tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, text)) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.helpIcon ts/commons/UIUtilsTemplate.soy:283"' : '') + '><i class="grey help circle icon"></i></span>');
};
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$helpIcon.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $helpIcon.soyTemplateName = 'ts.commons.UIUtilsTemplate.helpIcon';
}


/**
 * @param {!$findingMessage.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $findingMessage = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.finding);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} finding
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingMessage$ = function($$areYouAnInternalCaller, $ijData, finding) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.findingMessage']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.findingMessage']({finding: finding}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml(finding.renderedMessage ? soy.$$escapeHtml(finding.renderedMessage) : soy.$$escapeHtml(finding.message));
};
export { $findingMessage as findingMessage };
export { $findingMessage$ as findingMessage$ };
/**
 * @typedef {{
 *  finding: ?,
 * }}
 */
$findingMessage.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingMessage.soyTemplateName = 'ts.commons.UIUtilsTemplate.findingMessage';
}


/**
 * @param {!$tabContainer.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $tabContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $tabContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.tabNames, opt_data.tabMenuClassPrefix, opt_data.indexOfActiveTab, opt_data.tabAnnotations, opt_data.dontAddTabContentPanes);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} tabNames
 * @param {string} tabMenuClassPrefix
 * @param {null|number|undefined=} indexOfActiveTab
 * @param {!Array<null|number|string|undefined>|null|undefined=} tabAnnotations
 * @param {boolean|null|undefined=} dontAddTabContentPanes
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $tabContainer$ = function($$areYouAnInternalCaller, $ijData, tabNames, tabMenuClassPrefix, indexOfActiveTab, tabAnnotations, dontAddTabContentPanes) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.tabContainer']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.tabContainer']({tabNames: tabNames, tabMenuClassPrefix: tabMenuClassPrefix, indexOfActiveTab: indexOfActiveTab, tabAnnotations: tabAnnotations, dontAddTabContentPanes: dontAddTabContentPanes}, $ijData);
  }
  soy.assertParamType(Array.isArray(tabNames), 'tabNames', tabNames, '@param', '!Array<string>');
  soy.assertParamType(typeof tabMenuClassPrefix === 'string', 'tabMenuClassPrefix', tabMenuClassPrefix, '@param', 'string');
  soy.assertParamType(indexOfActiveTab == null || typeof indexOfActiveTab === 'number', 'indexOfActiveTab', indexOfActiveTab, '@param', 'null|number|undefined');
  soy.assertParamType(tabAnnotations == null || Array.isArray(tabAnnotations), 'tabAnnotations', tabAnnotations, '@param', '!Array<null|number|string|undefined>|null|undefined');
  soy.assertParamType(dontAddTabContentPanes == null || typeof dontAddTabContentPanes === 'boolean', 'dontAddTabContentPanes', dontAddTabContentPanes, '@param', 'boolean|null|undefined');
  let $output = '<div class="ui pointing secondary menu ' + soy.$$escapeHtmlAttribute(tabMenuClassPrefix) + '-tab-menu"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.tabContainer ts/commons/UIUtilsTemplate.soy:314"' : '') + '>';
  const tabName1815List = tabNames;
  const tabName1815ListLen = tabName1815List.length;
  for (let tabName1815Index = 0; tabName1815Index < tabName1815ListLen; tabName1815Index++) {
    const tabName1815Data = tabName1815List[tabName1815Index];
    $output += '<div id="tab-item-' + soy.$$escapeHtmlAttribute(tabName1815Data) + '" class="tab item ' + (tabName1815Index == (indexOfActiveTab != null ? indexOfActiveTab : 0) ? ' active' : '') + '" style="' + (soy.$$coerceToBoolean(tabAnnotations) && !tabAnnotations[/** @type {?} */ (tabName1815Index)] ? ' padding-bottom: 1.1em' : '') + '" data-index="' + soy.$$escapeHtmlAttribute(tabName1815Index) + '" data-tab="tab-' + soy.$$escapeHtmlAttribute(tabName1815Data) + '">' + soy.$$escapeHtml(tabName1815Data) + (soy.$$coerceToBoolean(tabAnnotations) && soy.$$coerceToBoolean(tabAnnotations[/** @type {?} */ (tabName1815Index)]) ? $circularLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, tabAnnotations[/** @type {?} */ (tabName1815Index)], true) : '') + '</div>';
  }
  $output += '<div class="invisible right item"></div></div>';
  if (!dontAddTabContentPanes) {
    const tabName1869List = tabNames;
    const tabName1869ListLen = tabName1869List.length;
    for (let tabName1869Index = 0; tabName1869Index < tabName1869ListLen; tabName1869Index++) {
      const tabName1869Data = tabName1869List[tabName1869Index];
      $output += '<div class="ui bottom attached tab tab-content ' + (tabName1869Index == (indexOfActiveTab != null ? indexOfActiveTab : 0) ? ' active' : '') + '" data-index="' + soy.$$escapeHtmlAttribute(tabName1869Index) + '" data-tab="tab-' + soy.$$escapeHtmlAttribute(tabName1869Data) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.tabContainer ts/commons/UIUtilsTemplate.soy:341"' : '') + '></div>';
    }
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $tabContainer as tabContainer };
export { $tabContainer$ as tabContainer$ };
/**
 * @typedef {{
 *  tabNames: !Array<string>,
 *  tabMenuClassPrefix: string,
 *  indexOfActiveTab?: (null|number|undefined),
 *  tabAnnotations?: (!Array<null|number|string|undefined>|null|undefined),
 *  dontAddTabContentPanes?: (boolean|null|undefined),
 * }}
 */
$tabContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $tabContainer.soyTemplateName = 'ts.commons.UIUtilsTemplate.tabContainer';
}


/**
 * @param {!$circularLabel.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 */
const $circularLabel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $circularLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text, opt_data.useSmallLabel);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number|string} text
 * @param {boolean|null|undefined=} useSmallLabel
 * @return {!goog_soy_data_SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $circularLabel$ = function($$areYouAnInternalCaller, $ijData, text, useSmallLabel) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.circularLabel']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.circularLabel']({text: text, useSmallLabel: useSmallLabel}, $ijData);
  }
  soy.assertParamType(typeof text === 'number' || typeof text === 'string', 'text', text, '@param', 'number|string');
  soy.assertParamType(useSmallLabel == null || typeof useSmallLabel === 'boolean', 'useSmallLabel', useSmallLabel, '@param', 'boolean|null|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span class="ui circular ' + (useSmallLabel ? 'small' : '') + ' light label" style="vertical-align: middle"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.circularLabel ts/commons/UIUtilsTemplate.soy:353"' : '') + '>' + soy.$$escapeHtml(text) + '</span>');
};
/**
 * @typedef {{
 *  text: (number|string),
 *  useSmallLabel?: (boolean|null|undefined),
 * }}
 */
$circularLabel.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $circularLabel.soyTemplateName = 'ts.commons.UIUtilsTemplate.circularLabel';
}


/**
 * @param {!$tooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtmlAttribute}
 */
const $tooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data_SanitizedHtmlAttribute}
 * @suppress {checkTypes}
 */
const $tooltip$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.tooltip']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.tooltip']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtmlAttribute(text ? 'title="' + soy.$$escapeHtmlAttribute(text) + '"' : '');
};
export { $tooltip as tooltip };
export { $tooltip$ as tooltip$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$tooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $tooltip.soyTemplateName = 'ts.commons.UIUtilsTemplate.tooltip';
}


/**
 * @param {!$textWithLineBreaks.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $textWithLineBreaks = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $textWithLineBreaks$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.textLines);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {!Array<string>} textLines
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $textWithLineBreaks$ = function($$areYouAnInternalCaller, $ijData, textLines) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.textWithLineBreaks']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.textWithLineBreaks']({textLines: textLines}, $ijData);
  }
  soy.assertParamType(Array.isArray(textLines), 'textLines', textLines, '@param', '!Array<string>');
  let $output = '';
  const textLine1919List = textLines;
  const textLine1919ListLen = textLine1919List.length;
  for (let textLine1919Index = 0; textLine1919Index < textLine1919ListLen; textLine1919Index++) {
    const textLine1919Data = textLine1919List[textLine1919Index];
    $output += (textLine1919Index > 0 ? '<br' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.textWithLineBreaks ts/commons/UIUtilsTemplate.soy:373"' : '') + '>' : '') + soy.$$escapeHtml(textLine1919Data);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $textWithLineBreaks as textWithLineBreaks };
export { $textWithLineBreaks$ as textWithLineBreaks$ };
/**
 * @typedef {{
 *  textLines: !Array<string>,
 * }}
 */
$textWithLineBreaks.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $textWithLineBreaks.soyTemplateName = 'ts.commons.UIUtilsTemplate.textWithLineBreaks';
}


/**
 * @param {!$inputSelect.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $inputSelect = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $inputSelect$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.selectId, opt_data.selectItems, opt_data.selectedItem, opt_data.includeEmptyOption);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} selectId
 * @param {!Array<string>} selectItems
 * @param {null|string|undefined=} selectedItem
 * @param {boolean|null|undefined=} includeEmptyOption
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $inputSelect$ = function($$areYouAnInternalCaller, $ijData, selectId, selectItems, selectedItem, includeEmptyOption) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.inputSelect']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.inputSelect']({selectId: selectId, selectItems: selectItems, selectedItem: selectedItem, includeEmptyOption: includeEmptyOption}, $ijData);
  }
  soy.assertParamType(typeof selectId === 'string', 'selectId', selectId, '@param', 'string');
  soy.assertParamType(Array.isArray(selectItems), 'selectItems', selectItems, '@param', '!Array<string>');
  soy.assertParamType(selectedItem == null || typeof selectedItem === 'string', 'selectedItem', selectedItem, '@param', 'null|string|undefined');
  soy.assertParamType(includeEmptyOption == null || typeof includeEmptyOption === 'boolean', 'includeEmptyOption', includeEmptyOption, '@param', 'boolean|null|undefined');
  let $output = '<select class="ui dropdown" id="' + soy.$$escapeHtmlAttribute(selectId) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.inputSelect ts/commons/UIUtilsTemplate.soy:392"' : '') + '>' + (includeEmptyOption ? '<option value="" selected="selected">- Please select -</option>' : '');
  const item1951List = selectItems;
  const item1951ListLen = item1951List.length;
  for (let item1951Index = 0; item1951Index < item1951ListLen; item1951Index++) {
    const item1951Data = item1951List[item1951Index];
    $output += '<option' + (!includeEmptyOption && soy.$$equals(item1951Data, selectedItem) ? ' selected="selected"' : '') + '>' + soy.$$escapeHtml(item1951Data) + '</option>';
  }
  $output += '</select>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $inputSelect as inputSelect };
export { $inputSelect$ as inputSelect$ };
/**
 * @typedef {{
 *  selectId: string,
 *  selectItems: !Array<string>,
 *  selectedItem?: (null|string|undefined),
 *  includeEmptyOption?: (boolean|null|undefined),
 * }}
 */
$inputSelect.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $inputSelect.soyTemplateName = 'ts.commons.UIUtilsTemplate.inputSelect';
}


/**
 * @param {!$simulinkSignalDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $simulinkSignalDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkSignalDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.source, opt_data.target, opt_data.signals, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} source
 * @param {?} target
 * @param {!Array<?>} signals
 * @param {?} maxDialogContentHeight
 * @param {?} maxDialogContentWidth
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkSignalDialog$ = function($$areYouAnInternalCaller, $ijData, source, target, signals, maxDialogContentHeight, maxDialogContentWidth) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.simulinkSignalDialog']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.simulinkSignalDialog']({source: source, target: target, signals: signals, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth}, $ijData);
  }
  soy.assertParamType(Array.isArray(signals), 'signals', signals, '@param', '!Array<?>');
  let $output = '';
  if (signals) {
    $output += '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.simulinkSignalDialog ts/commons/UIUtilsTemplate.soy:419"' : '') + '><table class="ui compact striped selectable table"><thead><th><span>' + soy.$$escapeHtml(source) + ' <i class="long arrow alternate right icon"></i> ' + soy.$$escapeHtml(target) + '</span></th><thead>';
    const signal2013List = signals;
    const signal2013ListLen = signal2013List.length;
    for (let signal2013Index = 0; signal2013Index < signal2013ListLen; signal2013Index++) {
      const signal2013Data = signal2013List[signal2013Index];
      $output += '<tr><td width="100%" style="font-weight: 600">' + soy.$$escapeHtml(signal2013Data) + '</td></tr>';
    }
    $output += '</table></div>';
  } else {
    $output += '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.simulinkSignalDialog ts/commons/UIUtilsTemplate.soy:439"' : '') + '><p>There is no signal information available for this file.</p><p>Make sure architecture analysis is enabled.</p></div>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkSignalDialog as simulinkSignalDialog };
export { $simulinkSignalDialog$ as simulinkSignalDialog$ };
/**
 * @typedef {{
 *  source: ?,
 *  target: ?,
 *  signals: !Array<?>,
 *  maxDialogContentHeight: ?,
 *  maxDialogContentWidth: ?,
 * }}
 */
$simulinkSignalDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkSignalDialog.soyTemplateName = 'ts.commons.UIUtilsTemplate.simulinkSignalDialog';
}


/**
 * @param {!$page.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $page = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $page$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.caption, opt_data.description, opt_data.buttons, opt_data.fullWidth, opt_data.fullWidthValue, opt_data.captionPrefix, opt_data.captionLabel, opt_data.headerClass, opt_data.headerButtonsClass, opt_data.segmentBorder);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} caption
 * @param {null|string|undefined=} description
 * @param {!Array<?>|null|undefined=} buttons
 * @param {?=} fullWidth
 * @param {?=} fullWidthValue
 * @param {null|number|string|undefined=} captionPrefix
 * @param {null|string|undefined=} captionLabel
 * @param {null|string|undefined=} headerClass
 * @param {null|string|undefined=} headerButtonsClass
 * @param {boolean|null|undefined=} segmentBorder
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $page$ = function($$areYouAnInternalCaller, $ijData, caption, description, buttons, fullWidth, fullWidthValue, captionPrefix, captionLabel, headerClass, headerButtonsClass, segmentBorder) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.page']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.page']({caption: caption, description: description, buttons: buttons, fullWidth: fullWidth, fullWidthValue: fullWidthValue, captionPrefix: captionPrefix, captionLabel: captionLabel, headerClass: headerClass, headerButtonsClass: headerButtonsClass, segmentBorder: segmentBorder}, $ijData);
  }
  soy.assertParamType(typeof caption === 'string', 'caption', caption, '@param', 'string');
  soy.assertParamType(description == null || typeof description === 'string', 'description', description, '@param', 'null|string|undefined');
  soy.assertParamType(buttons == null || Array.isArray(buttons), 'buttons', buttons, '@param', '!Array<?>|null|undefined');
  soy.assertParamType(captionPrefix == null || typeof captionPrefix === 'number' || typeof captionPrefix === 'string', 'captionPrefix', captionPrefix, '@param', 'null|number|string|undefined');
  soy.assertParamType(captionLabel == null || typeof captionLabel === 'string', 'captionLabel', captionLabel, '@param', 'null|string|undefined');
  soy.assertParamType(headerClass == null || typeof headerClass === 'string', 'headerClass', headerClass, '@param', 'null|string|undefined');
  soy.assertParamType(headerButtonsClass == null || typeof headerButtonsClass === 'string', 'headerButtonsClass', headerButtonsClass, '@param', 'null|string|undefined');
  soy.assertParamType(segmentBorder == null || typeof segmentBorder === 'boolean', 'segmentBorder', segmentBorder, '@param', 'boolean|null|undefined');
  let param2057 = '<div class="page-header"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:479"' : '') + '><h1 class="ts-headline ' + soy.$$escapeHtmlAttribute(headerClass != null ? headerClass : '') + '">' + (captionPrefix ? '<span class="ts-headline-prefix">' + soy.$$escapeHtml(captionPrefix) + '</span>' : '') + soy.$$escapeHtml(caption) + '  ' + (captionLabel ? '<span><span class="ui circular label">' + soy.$$escapeHtml(captionLabel) + '</span></span>' : '') + '</h1>';
  if (soy.$$coerceToBoolean(buttons) && buttons.length > 0) {
    param2057 += '<div class="header-buttons ' + soy.$$escapeHtmlAttribute(headerButtonsClass != null ? headerButtonsClass : '') + '">';
    const button2111List = buttons;
    const button2111ListLen = button2111List.length;
    for (let button2111Index = 0; button2111Index < button2111ListLen; button2111Index++) {
      const button2111Data = button2111List[button2111Index];
      param2057 += !button2111Data.href ? '<button id="' + soy.$$escapeHtmlAttribute(button2111Data.id) + '" class="ui button ' + (button2111Data.icon ? 'icon ' : '') + (button2111Data.classParams ? soy.$$escapeHtmlAttribute(button2111Data.classParams) : '') + ' ' + (button2111Data.primary ? 'primary' : '') + '"' + (button2111Data.tooltip ? ' title="' + soy.$$escapeHtmlAttribute(button2111Data.tooltip) + '"' : '') + '><i' + (button2111Data.iconId ? ' id="' + soy.$$escapeHtmlAttribute(button2111Data.iconId) + '"' : '') + ' class="' + soy.$$escapeHtmlAttribute(button2111Data.icon) + '"></i> ' + soy.$$escapeHtml(button2111Data.title) + '</button>' : '<a id="' + soy.$$escapeHtmlAttribute(button2111Data.id) + '" class="ui button ' + (button2111Data.icon ? 'icon ' : '') + (button2111Data.classParams ? soy.$$escapeHtmlAttribute(button2111Data.classParams) : '') + ' ' + (button2111Data.primary ? 'primary' : '') + '"' + (button2111Data.tooltip ? ' title="' + soy.$$escapeHtmlAttribute(button2111Data.tooltip) + '"' : '') + ' href=' + soy.$$escapeHtmlAttributeNospace(soy.$$filterNormalizeUri(button2111Data.href)) + (button2111Data.openInNewTab ? ' target="_blank"' : '') + '><i' + (button2111Data.iconId ? ' id="' + soy.$$escapeHtmlAttribute(button2111Data.iconId) + '"' : '') + ' class="' + soy.$$escapeHtmlAttribute(button2111Data.icon) + '"></i> ' + soy.$$escapeHtml(button2111Data.title) + '</a>';
    }
    param2057 += '</div>';
  }
  param2057 += '</div>' + (description ? '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:509"' : '') + '>' + soy.$$escapeHtml(description) + '</div>' : '') + '<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.page ts/commons/UIUtilsTemplate.soy:511"' : '') + '>' + $alerts(null, $ijData) + '</div>';
  let $output = '' + $centeredDiv$(soy.$$internalCallMarkerDoNotUse, $ijData, soy.VERY_UNSAFE.$$ordainSanitizedHtmlForInternalBlocks(param2057), fullWidth, fullWidthValue, segmentBorder);
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $page as page };
export { $page$ as page$ };
/**
 * @typedef {{
 *  caption: string,
 *  description?: (null|string|undefined),
 *  buttons?: (!Array<?>|null|undefined),
 *  fullWidth?: (?|undefined),
 *  fullWidthValue?: (?|undefined),
 *  captionPrefix?: (null|number|string|undefined),
 *  captionLabel?: (null|string|undefined),
 *  headerClass?: (null|string|undefined),
 *  headerButtonsClass?: (null|string|undefined),
 *  segmentBorder?: (boolean|null|undefined),
 * }}
 */
$page.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $page.soyTemplateName = 'ts.commons.UIUtilsTemplate.page';
}


/**
 * @param {!$issueStatusLabel.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data_SanitizedHtml}
 */
const $issueStatusLabel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $issueStatusLabel$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.issue, opt_data.size, opt_data.useBasicStyle);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} issue
 * @param {?=} size
 * @param {?=} useBasicStyle
 * @return {!goog_soy_data_SanitizedHtml}
 * @suppress {checkTypes}
 */
const $issueStatusLabel$ = function($$areYouAnInternalCaller, $ijData, issue, size, useBasicStyle) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.commons.UIUtilsTemplate.issueStatusLabel']) {
    return soy.$$stubsMap['ts.commons.UIUtilsTemplate.issueStatusLabel']({issue: issue, size: size, useBasicStyle: useBasicStyle}, $ijData);
  }
  let $output = '';
  let labelColor__soy2236 = '';
  const $tmp = issue.status;
  switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
    case 'Open':
    case 'open':
    case 'unresolved':
    case 'New':
    case 'Draft':
      labelColor__soy2236 += ' blue';
      break;
    case 'In Review':
    case 'In review':
    case 'Reviewed':
    case 'reviewed':
    case 'Review':
    case 'review':
    case 'Yellow':
    case 'yellow':
    case 'In Progress':
    case 'In progress':
    case 'In development':
    case 'In Development':
      labelColor__soy2236 += 'orange';
      break;
    case 'Closed':
    case 'closed':
    case 'resolved':
    case 'Resolved':
    case 'done':
    case 'Done':
    case 'Merged':
    case 'Approved':
      labelColor__soy2236 += ' green';
      break;
    case 'Rejected':
      labelColor__soy2236 += ' red';
      break;
    default:
      labelColor__soy2236 += ' grey';
  }
  $output += !soy.$$equals(issue.status, '') ? '<span class="ui label ' + (useBasicStyle ? 'basic' : '') + ' ' + (size != null ? soy.$$escapeHtmlAttribute(size) : 'medium') + soy.$$escapeHtmlAttribute(labelColor__soy2236) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.commons.UIUtilsTemplate.issueStatusLabel ts/commons/UIUtilsTemplate.soy:545"' : '') + '>' + soy.$$escapeHtml(issue.status) + '</span>' : '';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $issueStatusLabel as issueStatusLabel };
export { $issueStatusLabel$ as issueStatusLabel$ };
/**
 * @typedef {{
 *  issue: ?,
 *  size?: (?|undefined),
 *  useBasicStyle?: (?|undefined),
 * }}
 */
$issueStatusLabel.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $issueStatusLabel.soyTemplateName = 'ts.commons.UIUtilsTemplate.issueStatusLabel';
}
