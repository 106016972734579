// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {?$codeContainer.Params=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $codeContainer = function(opt_data, opt_ijData) {
  opt_data = opt_data || {};
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $codeContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.heightLimit, opt_data.minHeight);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {null|number|undefined=} heightLimit
 * @param {null|string|undefined=} minHeight
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $codeContainer$ = function($$areYouAnInternalCaller, $ijData, heightLimit, minHeight) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer']({heightLimit: heightLimit, minHeight: minHeight}, $ijData);
  }
  soy.assertParamType(heightLimit == null || typeof heightLimit === 'number', 'heightLimit', heightLimit, '@param', 'null|number|undefined');
  soy.assertParamType(minHeight == null || typeof minHeight === 'string', 'minHeight', minHeight, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ts-flex-code-container"' + (heightLimit != null ? ' style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(heightLimit)) + 'px;"' : '') + (minHeight != null ? ' style="min-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(minHeight)) + ';"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:21"' : '') + '><div class="ts-code-scroll-container"></div><canvas width="16" style="display: none;position: absolute;"></canvas></div>');
};
export { $codeContainer as codeContainer };
export { $codeContainer$ as codeContainer$ };
/**
 * @typedef {{
 *  heightLimit?: (null|number|undefined),
 *  minHeight?: (null|string|undefined),
 * }}
 */
$codeContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $codeContainer.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeContainer';
}


/**
 * @param {!$coverageSetting.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $coverageSetting = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $coverageSetting$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.partitionsWithoutCoverage, opt_data.lineCoveragePartitions);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} partitionsWithoutCoverage
 * @param {?=} lineCoveragePartitions
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $coverageSetting$ = function($$areYouAnInternalCaller, $ijData, partitionsWithoutCoverage, lineCoveragePartitions) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting']({lineCoveragePartitions: lineCoveragePartitions, partitionsWithoutCoverage: partitionsWithoutCoverage}, $ijData);
  }
  let $output = '<div class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:41"' : '') + '><div class="ui header" id="annotate-test-coverage" style="font-size: 1em; margin-bottom: .5em; margin-top: 1em"><label for="show-testcoverage">Annotate test coverage</label><span id="color-legend" class="ml-1"></span><div class="ui checkbox pull-right show-coverage-checkbox"><input type="checkbox" id="show-testcoverage"/><label></label></div><i class="coverage-loading-spinner blue spinner loading icon pull-right"></i></div></div>' + (lineCoveragePartitions.length > 0 ? '<div id="partition-selector-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:53"' : '') + '></div>' : '') + '<table class="ui very basic compact table"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:55"' : '') + '>';
  if (soy.$$equals(lineCoveragePartitions.length, 0)) {
    $output += '<tbody><tr><td style="font-weight: normal;" colspan="2">No coverage uploaded, showing only coverable lines</td></tr></tbody>';
  } else {
    $output += '<thead><thead><tr><th>Coverage source</th><th>Latest Upload</th></tr></thead><tbody>';
    const partition7837List = lineCoveragePartitions;
    const partition7837ListLen = partition7837List.length;
    for (let partition7837Index = 0; partition7837Index < partition7837ListLen; partition7837Index++) {
      const partition7837Data = partition7837List[partition7837Index];
      $output += '<tr><td>' + soy.$$escapeHtml(partition7837Data.name) + '</td><td>' + soy.$$escapeHtml(partition7837Data.formattedTimestamp) + '</td></tr>';
    }
    if (partitionsWithoutCoverage.length > 0) {
      $output += '<tr><td colspan="2"><i>';
      if (partitionsWithoutCoverage.length > 5) {
        $output += 'No coverage in other coverage sources';
      } else {
        $output += 'No coverage in coverage sources:&nbsp;';
        const partitionWithoutCoverage7870List = partitionsWithoutCoverage;
        const partitionWithoutCoverage7870ListLen = partitionWithoutCoverage7870List.length;
        for (let partitionWithoutCoverage7870Index = 0; partitionWithoutCoverage7870Index < partitionWithoutCoverage7870ListLen; partitionWithoutCoverage7870Index++) {
          const partitionWithoutCoverage7870Data = partitionWithoutCoverage7870List[partitionWithoutCoverage7870Index];
          $output += (partitionWithoutCoverage7870Index > 0 ? ', ' : '') + soy.$$escapeHtml(partitionWithoutCoverage7870Data);
        }
      }
      $output += '</i></td></tr>';
    }
    $output += '</tbody>';
  }
  $output += '</table>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $coverageSetting as coverageSetting };
export { $coverageSetting$ as coverageSetting$ };
/**
 * @typedef {{
 *  lineCoveragePartitions?: (?|undefined),
 *  partitionsWithoutCoverage: ?,
 * }}
 */
$coverageSetting.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $coverageSetting.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.coverageSetting';
}


/**
 * @param {!$dependencyDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $dependencyDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.pathDependencies, opt_data.inversePathDependencies, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {!Array<?>} pathDependencies
 * @param {!Array<?>} inversePathDependencies
 * @param {?} maxDialogContentHeight
 * @param {?} maxDialogContentWidth
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $dependencyDialog$ = function($$areYouAnInternalCaller, $ijData, project, pathDependencies, inversePathDependencies, maxDialogContentHeight, maxDialogContentWidth, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog']({project: project, pathDependencies: pathDependencies, inversePathDependencies: inversePathDependencies, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(Array.isArray(pathDependencies), 'pathDependencies', pathDependencies, '@param', '!Array<?>');
  soy.assertParamType(Array.isArray(inversePathDependencies), 'inversePathDependencies', inversePathDependencies, '@param', '!Array<?>');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(soy.$$coerceToBoolean(pathDependencies) || soy.$$coerceToBoolean(inversePathDependencies) ? '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px; overflow: auto;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:115"' : '') + '><table class="ui very basic compact table">' + $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, 'long arrow right icon', 'Outgoing', pathDependencies.length) + $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, project, pathDependencies, true, commit) + '</table><table class="ui very basic compact table">' + $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, 'long arrow left icon', 'Incoming', inversePathDependencies.length) + $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, project, inversePathDependencies, false, commit) + '</table></div>' : '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:147"' : '') + '><p>There is no dependency information available for this file.</p><p>Make sure architecture analysis is enabled.</p></div>');
};
export { $dependencyDialog as dependencyDialog };
export { $dependencyDialog$ as dependencyDialog$ };
/**
 * @typedef {{
 *  project: string,
 *  pathDependencies: !Array<?>,
 *  inversePathDependencies: !Array<?>,
 *  maxDialogContentHeight: ?,
 *  maxDialogContentWidth: ?,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$dependencyDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyDialog';
}


/**
 * @param {!$dependencyTableHead.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 */
const $dependencyTableHead = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyTableHead$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.arrowDirection, opt_data.message, opt_data.dependencyCount);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} arrowDirection
 * @param {?} message
 * @param {number} dependencyCount
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $dependencyTableHead$ = function($$areYouAnInternalCaller, $ijData, arrowDirection, message, dependencyCount) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead']({arrowDirection: arrowDirection, message: message, dependencyCount: dependencyCount}, $ijData);
  }
  soy.assertParamType(typeof dependencyCount === 'number', 'dependencyCount', dependencyCount, '@param', 'number');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<thead' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:166"' : '') + '><th><span class="file text icon"></span><span class="' + soy.$$escapeHtmlAttribute(arrowDirection) + '" style="margin-left: 4px; margin-right: 4px"></span><span class="file text outline icon"></span><span style="margin-left: 4px">' + soy.$$escapeHtml(message) + ' (' + soy.$$escapeHtml(dependencyCount) + ')</span></th><th></th><thead>');
};
/**
 * @typedef {{
 *  arrowDirection: ?,
 *  message: ?,
 *  dependencyCount: number,
 * }}
 */
$dependencyTableHead.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyTableHead.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableHead';
}


/**
 * @param {!$dependencyTableBody.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 */
const $dependencyTableBody = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $dependencyTableBody$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.dependencies, opt_data.isOutgoing, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {!Array<?>} dependencies
 * @param {boolean} isOutgoing
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $dependencyTableBody$ = function($$areYouAnInternalCaller, $ijData, project, dependencies, isOutgoing, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody']({project: project, dependencies: dependencies, isOutgoing: isOutgoing, commit: commit}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(Array.isArray(dependencies), 'dependencies', dependencies, '@param', '!Array<?>');
  soy.assertParamType(typeof isOutgoing === 'boolean', 'isOutgoing', isOutgoing, '@param', 'boolean');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  let $output = '<tbody' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:194"' : '') + '>';
  if (soy.$$coerceToBoolean(dependencies) && dependencies.length > 0) {
    const dependency8016List = dependencies;
    const dependency8016ListLen = dependency8016List.length;
    for (let dependency8016Index = 0; dependency8016Index < dependency8016ListLen; dependency8016Index++) {
      const dependency8016Data = dependency8016List[dependency8016Index];
      $output += '<tr><td width="70%"><a class="dependency-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: dependency8016Data.dependencyUniformPath, commit: commit, selection: isOutgoing ? null : {startLine: dependency8016Data.dependencyLocations[/** @type {?} */ (0)], endLine: dependency8016Data.dependencyLocations[/** @type {?} */ ('0')]}}), $ijData))) + '">' + soy.$$escapeHtml(dependency8016Data.dependencyUniformPath) + '</a></td><td width="30%">';
      const i8052ListLen = Math.max(0, Math.ceil((10 - 0) / 1));
      for (let i8052Index = 0; i8052Index < i8052ListLen; i8052Index++) {
        const i8052Data = 0 + i8052Index * 1;
        $output += dependency8016Data.dependencyLocations[/** @type {?} */ (i8052Data)] ? (i8052Data > 0 ? ', ' : '') + '<a class="dependency-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: dependency8016Data.dependencyLocationUniformPath, commit: commit, selection: {startLine: dependency8016Data.dependencyLocations[/** @type {?} */ (i8052Data)], endLine: dependency8016Data.dependencyLocations[/** @type {?} */ (i8052Data)]}}), $ijData))) + '">' + soy.$$escapeHtml(dependency8016Data.dependencyLocations[/** @type {?} */ (i8052Data)]) + '</a>' : '';
      }
      $output += (dependency8016Data.dependencyLocations.length > 10 ? ', ...' : '') + '</td></tr>';
    }
  } else {
    $output += '<tr><td>No results found.</td></tr>';
  }
  $output += '</tbody>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  project: string,
 *  dependencies: !Array<?>,
 *  isOutgoing: boolean,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 * }}
 */
$dependencyTableBody.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $dependencyTableBody.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.dependencyTableBody';
}


/**
 * @param {!$architectureComponentAssignmentsDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $architectureComponentAssignmentsDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $architectureComponentAssignmentsDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.componentAssignments, opt_data.maxDialogContentHeight, opt_data.maxDialogContentWidth, opt_data.commit);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {?} componentAssignments
 * @param {number} maxDialogContentHeight
 * @param {number} maxDialogContentWidth
 * @param {null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}=} commit
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $architectureComponentAssignmentsDialog$ = function($$areYouAnInternalCaller, $ijData, project, componentAssignments, maxDialogContentHeight, maxDialogContentWidth, commit) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog']({project: project, commit: commit, componentAssignments: componentAssignments, maxDialogContentHeight: maxDialogContentHeight, maxDialogContentWidth: maxDialogContentWidth}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(commit == null || google.isObject(commit), 'commit', commit, '@param', 'null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}');
  soy.assertParamType(typeof maxDialogContentHeight === 'number', 'maxDialogContentHeight', maxDialogContentHeight, '@param', 'number');
  soy.assertParamType(typeof maxDialogContentWidth === 'number', 'maxDialogContentWidth', maxDialogContentWidth, '@param', 'number');
  return soy.VERY_UNSAFE.ordainSanitizedHtml(soy.$$coerceToBoolean(componentAssignments) && componentAssignments.length > 0 ? '<div class="dependency-dialog-content" style="max-height: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentHeight)) + 'px; max-width: ' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(maxDialogContentWidth)) + 'px;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:253"' : '') + '><table class="ui very basic compact table">' + $architectureTableBody(/** @type {?} */ ({project: project, commit: commit, componentAssignments: componentAssignments}), $ijData) + '</table></div>' : '<div class="dependency-dialog-content-missing"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:265"' : '') + '><p>There are no architectures available for this project.</p></div>');
};
export { $architectureComponentAssignmentsDialog as architectureComponentAssignmentsDialog };
export { $architectureComponentAssignmentsDialog$ as architectureComponentAssignmentsDialog$ };
/**
 * @typedef {{
 *  project: string,
 *  commit?: (null|undefined|{timestamp?: (null|number|undefined), branchName?: (null|string|undefined), previous?: (null|number|undefined),}),
 *  componentAssignments: ?,
 *  maxDialogContentHeight: number,
 *  maxDialogContentWidth: number,
 * }}
 */
$architectureComponentAssignmentsDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureComponentAssignmentsDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureComponentAssignmentsDialog';
}


/**
 * @param {!$architectureTableBody.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @private
 * @suppress {checkTypes}
 */
const $architectureTableBody = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody'](opt_data, $ijData);
  }
  /** @type {string} */
  const project = soy.assertParamType(typeof opt_data.project === 'string', 'project', opt_data.project, '@param', 'string');
  /** @type {?} */
  const commit = opt_data.commit;
  /** @type {?} */
  const componentAssignments = opt_data.componentAssignments;
  let $output = '<thead' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:282"' : '') + '><th><span style="margin-left: 4px">Architecture</span></th><th><span style="margin-left: 4px">Component</span></th><thead><tbody>';
  const assignment8173List = componentAssignments;
  const assignment8173ListLen = assignment8173List.length;
  for (let assignment8173Index = 0; assignment8173Index < assignment8173ListLen; assignment8173Index++) {
    const assignment8173Data = assignment8173List[assignment8173Index];
    $output += '<tr><td width="50%"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.architectureEditor(soy.$$assignDefaults({assessmentIdentifier: assignment8173Data.architecturePath, project: project, component: assignment8173Data.componentName}, opt_data), $ijData))) + '">' + soy.$$escapeHtml(assignment8173Data.architecturePath) + '</a></td><td width="50%">';
    const $tmp = assignment8173Data.sortKey;
    switch (google.isObject($tmp) ? $tmp.toString() : $tmp) {
      case 0:
        $output += '<div class="ui  blue label"><i class="clone icon"></i>/' + soy.$$escapeHtml(assignment8173Data.componentName) + '</div>';
        break;
      case 1:
        $output += '<div class="ui red label"><i class="exclamation triangle icon"></i>' + soy.$$escapeHtml(assignment8173Data.componentName) + '</div>';
        break;
      case 2:
        $output += '<div class="ui grey label"><i class="minus icon"></i>' + soy.$$escapeHtml(assignment8173Data.componentName) + '</div>';
        break;
    }
    $output += '</td></tr>';
  }
  $output += '</tbody>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
/**
 * @typedef {{
 *  project: string,
 *  commit: ?,
 *  componentAssignments: ?,
 * }}
 */
$architectureTableBody.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $architectureTableBody.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.architectureTableBody';
}


/**
 * @param {!$outdatedCoverageInfo.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $outdatedCoverageInfo = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $outdatedCoverageInfo$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.codeDate);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} codeDate
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $outdatedCoverageInfo$ = function($$areYouAnInternalCaller, $ijData, codeDate) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo']({codeDate: codeDate}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="test-coverage-date-warning" class="ui warning message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:331"' : '') + '>Test coverage information in one or more partition (see right sidebar) may be outdated as code was changed on ' + soy.$$escapeHtml(codeDate) + '.</div>');
};
export { $outdatedCoverageInfo as outdatedCoverageInfo };
export { $outdatedCoverageInfo$ as outdatedCoverageInfo$ };
/**
 * @typedef {{
 *  codeDate: ?,
 * }}
 */
$outdatedCoverageInfo.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $outdatedCoverageInfo.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.outdatedCoverageInfo';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $mcdcCoverageInfo = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="mcdc-coverage-info" class="ui info message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:343"' : '') + '>MC/DC coverage annotation active. Please hover over the numbers to get an explanation of the values.<span class="pull-right"><i id="dismiss-mcdc-info" class="close icon info-message-color"></i></span></div>');
};
export { $mcdcCoverageInfo as mcdcCoverageInfo };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $mcdcCoverageInfo.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.mcdcCoverageInfo';
}


/**
 * @param {!$simulinkContainer.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkContainer$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.useContainerStyles);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} useContainerStyles
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkContainer$ = function($$areYouAnInternalCaller, $ijData, useContainerStyles) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer']({useContainerStyles: useContainerStyles}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="simulink-container"' + (useContainerStyles ? ' style="height: 100%; border: 1px solid whiteSmoke; border-radius: 4px; overflow: auto; max-height: 100vh;"' : '') + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:356"' : '') + '></div>');
};
export { $simulinkContainer as simulinkContainer };
export { $simulinkContainer$ as simulinkContainer$ };
/**
 * @typedef {{
 *  useContainerStyles: ?,
 * }}
 */
$simulinkContainer.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkContainer.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkContainer';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkBreadcrumbs = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="simulink-breadcrumbs" class="ui large padded bold breadcrumb"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:366"' : '') + '><div class="section">Project</div></div>');
};
export { $simulinkBreadcrumbs as simulinkBreadcrumbs };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkBreadcrumbs.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbs';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkNoDrawableModel = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:375"' : '') + '>Simulink model cannot be drawn. See the logs for possible reasons</div>');
};
export { $simulinkNoDrawableModel as simulinkNoDrawableModel };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkNoDrawableModel.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkNoDrawableModel';
}


/**
 * @param {!$simulinkBreadcrumbsEntry.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkBreadcrumbsEntry = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkBreadcrumbsEntry$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.name, opt_data.link);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} name
 * @param {?} link
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkBreadcrumbsEntry$ = function($$areYouAnInternalCaller, $ijData, name, link) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry']({name: name, link: link}, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<a class="section" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(link)) + '" style="cursor: pointer;"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:388"' : '') + '>' + soy.$$escapeHtml(name) + '</a>');
};
export { $simulinkBreadcrumbsEntry as simulinkBreadcrumbsEntry };
export { $simulinkBreadcrumbsEntry$ as simulinkBreadcrumbsEntry$ };
/**
 * @typedef {{
 *  name: ?,
 *  link: ?,
 * }}
 */
$simulinkBreadcrumbsEntry.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkBreadcrumbsEntry.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkBreadcrumbsEntry';
}


/**
 * @param {!$simulinkFindingsMarkerTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkFindingsMarkerTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkFindingsMarkerTooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.findings, opt_data.colors, opt_data.qualifiedNames);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} project
 * @param {?} findings
 * @param {?} colors
 * @param {?} qualifiedNames
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkFindingsMarkerTooltip$ = function($$areYouAnInternalCaller, $ijData, project, findings, colors, qualifiedNames) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip']({project: project, findings: findings, colors: colors, qualifiedNames: qualifiedNames}, $ijData);
  }
  let $output = '<div class="tableWithClickableRows"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:404"' : '') + '><row><p>Assessment</p><p>Qualified Name</p><p>Category</p><p>Message</p></row>';
  const finding8407List = findings;
  const finding8407ListLen = finding8407List.length;
  for (let finding8407Index = 0; finding8407Index < finding8407ListLen; finding8407Index++) {
    const finding8407Data = finding8407List[finding8407Index];
    let findingDetailsLink__soy8409 = '' + $soy$ts$commons$LinkTemplate.findingDetails(/** @type {?} */ ({project: project, id: finding8407Data[/** @type {?} */ ('id')]}), $ijData);
    $output += '<row data-href="' + soy.$$escapeHtmlAttribute(findingDetailsLink__soy8409) + '" style="cursor: pointer"><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy8409)) + '"><div style="width: 20px; background-color:' + soy.$$escapeHtmlAttribute(soy.$$filterCssValue(colors[/** @type {?} */ (finding8407Data.assessment)].hexValue)) + '">&nbsp;</div></a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy8409)) + '">' + soy.$$escapeHtml(qualifiedNames[/** @type {?} */ (finding8407Index)]) + '</a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy8409)) + '">' + soy.$$escapeHtml(finding8407Data.categoryName) + '</a><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(findingDetailsLink__soy8409)) + '">' + $soy$ts$commons$UIUtilsTemplate.findingMessage$(soy.$$internalCallMarkerDoNotUse, $ijData, finding8407Data) + '</a></row>';
  }
  $output += '</div>';
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkFindingsMarkerTooltip as simulinkFindingsMarkerTooltip };
export { $simulinkFindingsMarkerTooltip$ as simulinkFindingsMarkerTooltip$ };
/**
 * @typedef {{
 *  project: ?,
 *  findings: ?,
 *  colors: ?,
 *  qualifiedNames: ?,
 * }}
 */
$simulinkFindingsMarkerTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkFindingsMarkerTooltip.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkFindingsMarkerTooltip';
}


/**
 * @param {!$simulinkCoverageTooltipContent.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $simulinkCoverageTooltipContent = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $simulinkCoverageTooltipContent$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.project, opt_data.coverageInfo, opt_data.coveragePercentage, opt_data.blockDefaultText, opt_data.showCoverage);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} project
 * @param {{coveredLines: number, coverableLines: number, mappedLocations: (!Array<{uniformPath: string, rawStartLine: number, rawEndLine: number,}>|null|undefined),}} coverageInfo
 * @param {string} coveragePercentage
 * @param {string} blockDefaultText
 * @param {boolean} showCoverage
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $simulinkCoverageTooltipContent$ = function($$areYouAnInternalCaller, $ijData, project, coverageInfo, coveragePercentage, blockDefaultText, showCoverage) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent']({project: project, coverageInfo: coverageInfo, coveragePercentage: coveragePercentage, blockDefaultText: blockDefaultText, showCoverage: showCoverage}, $ijData);
  }
  soy.assertParamType(typeof project === 'string', 'project', project, '@param', 'string');
  soy.assertParamType(google.isObject(coverageInfo), 'coverageInfo', coverageInfo, '@param', '{coveredLines: number, coverableLines: number, mappedLocations: (!Array<{uniformPath: string, rawStartLine: number, rawEndLine: number,}>|null|undefined),}');
  soy.assertParamType(typeof coveragePercentage === 'string', 'coveragePercentage', coveragePercentage, '@param', 'string');
  soy.assertParamType(typeof blockDefaultText === 'string', 'blockDefaultText', blockDefaultText, '@param', 'string');
  soy.assertParamType(typeof showCoverage === 'boolean', 'showCoverage', showCoverage, '@param', 'boolean');
  let $output = '' + soy.$$escapeHtml(blockDefaultText);
  if (showCoverage) {
    $output += '<hr' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:450"' : '') + '>';
    if (coverageInfo.coverableLines == 0) {
      $output += '<span style="font-weight:bold"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:452"' : '') + '>No mapped code</span>';
    } else {
      $output += '<span style="font-weight:bold"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:454"' : '') + '>Test Coverage:</span><ul' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:455"' : '') + '><li>Coverage:  ' + soy.$$escapeHtml(coveragePercentage) + '%, (covered ' + soy.$$escapeHtml(coverageInfo.coveredLines) + ' lines out of ' + soy.$$escapeHtml(coverageInfo.coverableLines) + ')</li>';
      if (soy.$$coerceToBoolean(coverageInfo.mappedLocations) && coverageInfo.mappedLocations.length > 0) {
        $output += '<li>Mapped Code Lines:</li><ul>';
        const coveredCode8526List = coverageInfo.mappedLocations;
        const coveredCode8526ListLen = coveredCode8526List.length;
        for (let coveredCode8526Index = 0; coveredCode8526Index < coveredCode8526ListLen; coveredCode8526Index++) {
          const coveredCode8526Data = coveredCode8526List[coveredCode8526Index];
          $output += '<li><a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri($soy$ts$commons$LinkTemplate.code(/** @type {?} */ ({project: project, uniformPath: coveredCode8526Data.uniformPath, selection: {startLine: coveredCode8526Data.rawStartLine, endLine: coveredCode8526Data.rawEndLine}}), $ijData))) + '"' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + coveredCode8526Data.uniformPath)) + '>' + soy.$$escapeHtml(coveredCode8526Data.uniformPath) + ':' + (coveredCode8526Data.rawStartLine ? ' ' + soy.$$escapeHtml(coveredCode8526Data.rawStartLine) + ' ' : '') + '-' + (coveredCode8526Data.rawEndLine ? ' ' + soy.$$escapeHtml(coveredCode8526Data.rawEndLine) + ' ' : '') + '</a></li>';
        }
        $output += '</ul>';
      }
      $output += '</ul>';
    }
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $simulinkCoverageTooltipContent as simulinkCoverageTooltipContent };
export { $simulinkCoverageTooltipContent$ as simulinkCoverageTooltipContent$ };
/**
 * @typedef {{
 *  project: string,
 *  coverageInfo: {coveredLines: number, coverableLines: number, mappedLocations: (!Array<{uniformPath: string, rawStartLine: number, rawEndLine: number,}>|null|undefined),},
 *  coveragePercentage: string,
 *  blockDefaultText: string,
 *  showCoverage: boolean,
 * }}
 */
$simulinkCoverageTooltipContent.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $simulinkCoverageTooltipContent.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.simulinkCoverageTooltipContent';
}


/**
 * @param {!$codeRegionActionBar.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $codeRegionActionBar = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $codeRegionActionBar$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.url);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} url
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $codeRegionActionBar$ = function($$areYouAnInternalCaller, $ijData, url) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar']({url: url}, $ijData);
  }
  soy.assertParamType(typeof url === 'string', 'url', url, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="code-region-action-menu ui compact icon top left pointing dropdown button"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:494"' : '') + '><i class="icon ellipsis vertical"></i><div class="menu"><div data-clipboard-text="' + soy.$$escapeHtmlAttribute(url) + '" class="item code-region-copy-link">Copy Link</div><div class="item code-region-remove-selection">Deselect</div><div class="item code-region-add-code-snippet-to-task" data-testid="add-to-task">Add code snippet to task</div></div></div>');
};
export { $codeRegionActionBar as codeRegionActionBar };
export { $codeRegionActionBar$ as codeRegionActionBar$ };
/**
 * @typedef {{
 *  url: string,
 * }}
 */
$codeRegionActionBar.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $codeRegionActionBar.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.codeRegionActionBar';
}


/**
 * @param {!$reviewCommentDialog.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $reviewCommentDialog = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $reviewCommentDialog$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.text);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} text
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $reviewCommentDialog$ = function($$areYouAnInternalCaller, $ijData, text) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog']) {
    return soy.$$stubsMap['ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog']({text: text}, $ijData);
  }
  soy.assertParamType(typeof text === 'string', 'text', text, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui form"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog ts/perspectives/metrics/code/TeamscaleCodePerspectiveTemplate.soy:516"' : '') + '><div class="field"><span class="muted">Please enter a descriptive message for your review comment:</span><textarea id="review-comment-text" rows="3" cols="80">' + soy.$$escapeHtmlRcdata(text) + '</textarea></div></div>');
};
export { $reviewCommentDialog as reviewCommentDialog };
export { $reviewCommentDialog$ as reviewCommentDialog$ };
/**
 * @typedef {{
 *  text: string,
 * }}
 */
$reviewCommentDialog.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $reviewCommentDialog.soyTemplateName = 'ts.perspectives.metrics.code.TeamscaleCodePerspectiveTemplate.reviewCommentDialog';
}
